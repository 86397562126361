















































































































































































































































































import { proyecto_fichaDto } from "@/shared/dtos/proyecto_fichaDto";
import { proyecto } from "@/shared/dtos/proyecto";
import departamentoModule from "@/store/modules/departamento-module";
import proyectoModule from "@/store/modules/proyecto-module";
import enumeracionModule from "@/store/modules/enumeracion-module";
import { Component, Vue } from "vue-property-decorator";
import { enumeracion } from "@/shared/dtos/enumeracion";
import { MessageService } from "@/shared/services/message-service";
import { API } from "@/shared/api";
import usuarioModule from "@/store/modules/usuario-module";
import { Usuario } from "@/shared/dtos/usuario";
import { document_fileDto } from "@/shared/dtos/document_fileDto";
import { ssmPermissionService } from "@/shared/services/PermissionService";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    DxDateBox: () => import("devextreme-vue/date-box"),
    IncidenciasHandler: () =>
      import("@/views/proyectos/incidencias/incidenciasHandler.vue"),
    TareasHandler: () => import("@/views/proyectos/tareas/tareasHandler.vue"),
    UsuariosListSelect: () => import("@/views/usuarios/UsuariosListSelect.vue"),
    DocumentsHandler: () => import("./Documentos/DocumentsHandler.vue"),
    XBtnOperativeActiveInactive: () =>
      import("@/components/TypeView/x-btnOperativeActiveInactive.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    UserAvatar: () => import("@/components/Usuarios/UserAvatar.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class ProyectoFormulario extends Vue {
  public estados_proyecto: enumeracion[] = [];
  public prioridades_proyecto: enumeracion[] = [];
  public select_tab = "tab-1";
  public show_dialog_user = false;
  show_qr: boolean = true;
  created() {
    if (this.is_new()) {
      proyectoModule.onGetproyectoficha(new proyecto_fichaDto());
    } else {
      proyectoModule.getproyectoficha(this.$route.params.id);
      this.GetFilesProyect();
    }
    proyectoModule.getproyectos();
    departamentoModule.getdepartamentos();
    enumeracionModule
      .getenumeraciones_fromtipo(1)
      .then((x: enumeracion[]) => (this.estados_proyecto = x));
    enumeracionModule
      .getenumeraciones_fromtipo(2)
      .then((x: enumeracion[]) => (this.prioridades_proyecto = x));
    usuarioModule.getusuarios();
  }

  public get datasource() {
    usuarioModule.SetCache({
      id_pro: Number.parseInt(this.$route.params.id),
      usuarios: proyectoModule.proyecto_ficha.Usuarios,
    });
    return proyectoModule.proyecto_ficha;
  }

  public get proyectos() {
    if (!this.is_new()) {
      return proyectoModule.proyectos.filter(
        (x) => x.id !== this.datasource.id
      );
    }
    return proyectoModule.proyectos;
  }
  public get estados() {
    return enumeracionModule.enumeraciones;
  }
  public get departamentos() {
    return departamentoModule.departamentos;
  }

  public get all_users() {
    return usuarioModule.usuarios.filter((x: Usuario) => x.tecnico === true);
  }

  public get files() {
    return proyectoModule.proyectos_files;
  }

  public GetFilesProyect() {
    return proyectoModule.getfilesproyecto(this.$route.params.id);
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }

  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          proyectoModule
            .guardar_ficha_proyecto(new proyecto_fichaDto(this.datasource))
            .then(() => this.cancelar());
        } else {
          proyectoModule
            .modificar_ficha_proyecto(this.datasource)
            .then(() => this.cancelar());
        }
      }
    });
  }

  public cancelar() {
    this.$router.back();
  }

  public eliminar() {
    MessageService.confirm(
      "¿Seguro que desea eliminar el proyecto?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          proyectoModule
            .eliminarproyecto(new proyecto(this.datasource))
            .then(() => this.$router.back());
        }
      }
    );
  }

  public eliminar_user_project(id_user: number) {
    MessageService.confirm(
      "¿Seguro que desea eliminar el usuario del proyecto?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          this.datasource.Usuarios.splice(
            this.datasource.Usuarios.findIndex(
              (x: Usuario) => x.id === id_user
            ),
            1
          );
        }
      }
    );
  }

  public HasPermission(permiso: string) {
    return ssmPermissionService.hasPermission(permiso);
  }

  public delete_file(file: document_fileDto) {
    proyectoModule.eliminar_documento_proyecto({
      proyecto: this.datasource,
      doc: file,
    });
  }

  public downlod_file(file: document_fileDto) {
    window.open(API.webApiBaseProyectos + this.datasource.id + "/" + file.name);
  }

  public Geturl() {
    return API.proyecto + "/documents/" + this.datasource.id;
  }
}
